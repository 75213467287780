/** @jsx jsx */
import React from "react"; //eslint-disable-line no-unused-vars
import { Box, Container, Flex, Heading, Link, Text, jsx } from "theme-ui";
import { graphql } from "gatsby";

import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SEO from "../components/SEO";

export const query = graphql`
  query {
    heroDots: file(relativePath: { eq: "hero-dots-2.svg" }) {
      id
      publicURL
      extension
    }
  }
`;

const RequestADemo = ({ data }) => {
  return (
    <>
      <SEO title="Request a Demo" />

      <Header
        heroImage={data.heroDots.publicURL}
        heroTitle="Request a Demo"
        heroVariant="small"
      />

      <Box as="main" py={[14, null, null, 16]}>
        <Container>
          <Flex sx={{ flexWrap: "wrap", justifyContent: "space-between" }}>
            <Box sx={{ flex: ["0 0 100%", null, null, "0 0 45%"] }}>
              <Heading as="h1" sx={{ fontSize: [5, null, null, 5] }}>
                To learn more about our platform or view a demo of SAA, send us
                an email, or fill out the following request form.
              </Heading>
              <Text as="p" mt={10} sx={{ opacity: "0.8" }}>
                Email us at{" "}
                <Link
                  href="mailto:hello@dosisinc.com"
                  sx={{ textDecoration: "none", color: "#000" }}
                >
                  hello@dosisinc.com
                </Link>
              </Text>
              <Text as="p" mt={10} sx={{ opacity: "0.8" }}>
                Call us at{" "}
                <Link
                  href="tel:650-383-0186"
                  sx={{ textDecoration: "none", color: "#000" }}
                >
                  (650) 383-0186
                </Link>
              </Text>
            </Box>

            <Box
              mt={[14, null, null, 0]}
              sx={{ flex: ["0 0 100%", null, null, "0 0 38%"] }}
            >
              <ContactForm/>
            </Box>
          </Flex>
        </Container>
      </Box>

      <Footer />
    </>
  );
};

export default RequestADemo;
