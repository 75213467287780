/** @jsx jsx */
import { useState } from "react";
import { Box, Button, Flex, Input, Textarea, Text, jsx } from "theme-ui";

const ContactForm = ({ name = false }) => {
  const [complete, setComplete] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();

    let formData = new FormData(e.target);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => setComplete(true))
      .catch(error => console.log("Form error: ", error));
  };

  if (complete) {
    return (
      <Box>
        <Text as="p" mt={10} sx={{ opacity: "0.8" }}>
          Thanks for your submission!
          <br />
          We will be in touch soon.
        </Text>
      </Box>
    );
  }

  return (
    <Box>
      <form
        name="contact"
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
        onSubmit={e => handleSubmit(e)}
      >
        <input type="hidden" name="form-name" value="contact" />
        <Input
          id="name"
          name="name"
          placeholder="Name*"
          type="text"
          required={true}
        />

        <Input
          id="email"
          mt={4}
          name="email"
          placeholder="Email*"
          type="email"
          required={true}
        />

        <Input
          id="organization-name"
          mt={4}
          name="organization-name"
          placeholder="Organization*"
          type="text"
          required={true}
        />

        <Input
          id="phone-number"
          mt={4}
          name="phone-number"
          placeholder="Phone Number"
          type="tel"
        />

        <Textarea
          id="message"
          mt={4}
          name="message"
          placeholder="Message"
          rows="5"
        />

        <Button mt={7} type="submit" sx={{ py: 4, px: 14 }}>
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default ContactForm;
